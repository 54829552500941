<template>
    <v-container fluid>
        <div class="pl-4 pr-0 pt-2 d-flex">
            <div class="pr-0 pt-4 left_filter">
                <setting-menu></setting-menu>
            </div>
            <div class="div_body pl-3 pr-0 mx-0">
                <EavMenu :slug="slug"/>
                <div class="d-flex align-center mb-4">
                    <h1>{{ $t('eav_property.plural') }}</h1>
                    <v-spacer></v-spacer>
                    <div class="buttons-block">
                        <Btn
                            color="secondary"
                            :to="{
                                    name: 'eav.type.property.edit',
                                    params: {
                                        id: 0,
                                        entity_type_slug: slug
                                    }
                                }">
                            {{ $t('create') }}
                        </Btn>
                        <Btn @click="fetchItems" plain>
                            <v-icon>mdi-refresh</v-icon>
                            {{ $t('refresh') }}
                        </Btn>
                    </div>
                </div>
                <ResizableTable
                    :columns="headers"
                    :rows="items"
                >
                    <template v-slot:name="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            <router-link :to="{name: 'eav.type.property.edit', params: {
                                        entity_type_slug: slug,
                                        id: item.id
                                    }}" class="cursor-pointer font_weight_600">
                                {{ item.name }}
                            </router-link>
                            <v-icon v-if="!item.deleted" @click="deleteitem(item.id)" small>mdi-delete-outline</v-icon>
                        </div>
                    </template>
                </ResizableTable>

                <v-pagination v-show="totalCountries > perPage" v-model="page" :total-visible="totalVisiblePag" class="pagination_table" :length="pageCount" :disabled="loading"></v-pagination>
            </div>
        </div>
    </v-container>
</template>
<script>

import {mapGetters} from "vuex"
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mask} from 'vue-the-mask'
import SettingMenu from "../components/SettingMenu";
import Btn from "@/components/Form/Btn.vue";
import EavMenu from "@/components/Eav/EavMenu.vue";

export default {
    name: "EavProperties",

    props: {
        slug: {
            type: String,
            required: true
        }
    },

    components: {
        EavMenu,
        Btn,
        SettingMenu
    },
    data() {
        return {
            headers: [
                {
                    active: true,
                    label: this.$t('eav.name'),
                    align: "left",
                    prop: "name"
                },
                {
                    active: true,
                    label: this.$t('eav.type'),
                    align: "left",
                    prop: "type"
                },
                {
                    active: true,
                    label: this.$t('eav.sort'),
                    align: "left",
                    prop: "sort"
                },
                {
                    active: true,
                    label: this.$t('eav.slug'),
                    align: "left",
                    prop: "slug"
                },
                {
                    active: true,
                    label: this.$t('eav.multiple'),
                    align: "left",
                    prop: "multiple"
                },
                {
                    active: true,
                    label: this.$t('eav.required'),
                    align: "left",
                    prop: "required"
                }
            ],
            items: [],
            perPage: 25,
            loading: false,
            page: 1,
            pageCount: 0,
            totalVisiblePag: 7,
            totalCountries: 0,
            sortBy: 'name',
            sortDir: false,
            options: {}
        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        this.fetchItems();
    },
    methods: {
        fetchItems() {
            this.loading = true;
            this.$http.get(`admin/eav/type/${this.slug}/property`)
                .then(response => {
                    this.items = response.data.data;
                })
                .catch(error => {
                    this.$store.dispatch('showError', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        deleteitem(itemId) {
            this.$http.delete(`admin/eav/type/${this.slug}/property/${itemId}`)
                .then(() => {
                    this.fetchItems();
                })
                .catch(error => {
                    this.$store.dispatch('showError', error);
                });
        },
    }
}
</script>
